import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@layout";
import {
  Grid,
  Title,
  Text,
  Box,
  Flex,
  Col,
  Button,
  Publish,
  UnPublish,
  ButtonIcon,
} from "@components";

import ModalConfirm from "@components/Modal/Confirm";
import ModalLoading from "@components/Modal/Loading";
import AdminContext from "@context";
import { getBlogs, getCategories, deleteBlog } from "@services";
import { format } from "@utils/format";
import { navigate } from "gatsby";

const BlogsPage = () => {
  const {
    auth: { token, uuid },
    catalogos,
    setCatalogos,
  } = useContext(AdminContext);

  const [blogs, setBlogs] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteBlog, setShowDeleteBlog] = useState(false);
  const [showProgressDeleteBlog, setShowProgressDeleteBlog] = useState(false);

  useEffect(() => {
    getBlogs(token, uuid).then(data => {
      setBlogs(data);
      console.log(data);
    });

    getCategories(token, uuid).then(data => {
      setCatalogos({
        ...catalogos,
        categories: data,
      });
      console.log(data);
    });
  }, []);

  const handleDelete = () => {
    if (deleteId !== null) {
      setShowProgressDeleteBlog(true);
      //	#falta borrar cada blog
      deleteBlog(deleteId, token).then(() => {
        getBlogs(token, uuid)
          .then(data => {
            setBlogs(data);
            setShowProgressDeleteBlog(false);
          })
          .catch(() => setShowProgressDeleteBlog(false));
      });
    }
  };
  return (
    <Layout title='Administración de blogs'>
      {showProgressDeleteBlog && (
        <ModalLoading
				title="Eliminando contenido..."
          show={showProgressDeleteBlog}
          onClose={() => setShowProgressDeleteBlog(false)}
        >estamos procesando tu solicitud</ModalLoading>
      )}
      {showDeleteBlog && (
        <ModalConfirm
          show={showDeleteBlog}
          onClose={() => setShowDeleteBlog(false)}
          onAccept={() => {
            setShowDeleteBlog(false);
            handleDelete();
          }}
        >
          Estas seguro de eliminar este blog?
        </ModalConfirm>
      )}
      <Grid className="pv:2">
        <Flex wrapper justify="end" className="mb:2">
          <Title marginless>Listado de blogs</Title>
          <Button onClick={() => navigate("/admin/blogs/agregar")} square primary>
            Nuevo blog
          </Button>
        </Flex>

        <Flex className="mt:2">
          {!!blogs?.length &&
            blogs.map(
              ({ author, id, title, category, active, lang }, index) => (
                <Box key={index} inline className="p:1 mb:05">
                  <Flex align="center">
                    <Col autofit className="pr:1">
                      <Text fw={500} fs={14}>
                        {id}
                      </Text>
                      <Text fs={10} opacity={0.2}>
                        ID
                      </Text>
                    </Col>
                    <Col xs={2}>
                      <Text fw={500} fs={14}>
                        {author}
                      </Text>
                      <Text fs={10} opacity={0.2}>
                        Autor
                      </Text>
                    </Col>
                    <Col xs={8}>
                      <Text fw={500} fs={14}>
                        {title}
                      </Text>
                      <Text fs={10} opacity={0.2}>
                        Titulo
                      </Text>
                    </Col>
                    <Col xs={2}>
                      <Text fw={500} fs={14}>
                        {category}
                      </Text>
                      <Text fs={10} opacity={0.2}>
                        Categoría
                      </Text>
                    </Col>

                    <Col xs={2}>
                      <Text fw={500} fs={14}>
                        {lang}
                      </Text>
                      <Text fs={10} opacity={0.2}>
                        Idioma
                      </Text>
                    </Col>

                    <Col xs={2}>
                      <Text fw={500} fs={14}>
                        {!!+active ? "Activo" : "Inactivo"}
                      </Text>
                      <Text fs={10} opacity={0.2}>
                        Status
                      </Text>
                    </Col>

                    <Col autofit className="t:center">
                      <ButtonIcon
                        onClick={() => {
                          setShowDeleteBlog(true);
                          setDeleteId(id);
                        }}
                        tooltip="Eliminar"
                        w={80}
                        direction="bottom"
                        pill
                        icon="delete"
                      />
                    </Col>
                    <Col autofit className="t:center">
                      <ButtonIcon
                        onClick={() => navigate(`/admin/blogs/${id}`)}
                        tooltip="Editar"
                        w={60}
                        direction="bottom"
                        pill
                        icon="edit"
                      />
                    </Col>
                  </Flex>
                </Box>
              )
            )}
        </Flex>
      </Grid>
    </Layout>
  );
};

export default BlogsPage;
