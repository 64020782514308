import React from "react";
import { Modal, Text, Flex, Col, Button } from "@components";
import secureAlert from "@assets/icons/secure-alert.svg";

export default ({ show, onClose, onAccept, children }) => {
	return (
		<Modal
			width={500}
			show={show}
			onClose={onClose}
			icon={secureAlert}
			unClosed
			title="Espera un momento!"
		>
			<div className="ph:2 pt:2">
				<Text align="center" fs={14} fw={500} line={1.4}>
					{children}
				</Text>
			</div>

			<Flex wrapper justify="center" className="ph:2 pv:2">
				<Col autofit className="ph:05">
					<Button square secondary onClick={() => onClose()}>Cancelar</Button>
				</Col>
				<Col autofit className="ph:05">
					<Button square primary onClick={() => onAccept()}>Aceptar</Button>
				</Col>
			</Flex>

		</Modal>
	);
};
